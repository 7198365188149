import frills3dRendering from '../assets/img/pricing/frills1.jpg';
import frillsOcularVisit from '../assets/img/pricing/frills-ocular-visit.jpg';
import frillsOnsiteStyling from '../assets/img/pricing/frills-onsite-styling.jpg';

export const ADDITIONAL_FRILL_LIST = [
  {
    description:
      'Need to visualize your space in 3D? No problem! Get a quick rendering in 7 business days!',
    discountedPrice: '7,999',
    frillDetails: [],
    frillImage: frills3dRendering,
    hasPriceStartLabel: false,
    isMirrored: false,
    title: '3D Rendering',
    price: '9,999',
  },
  {
    description: 'Let our experienced interior designers assist you in measuring your space.',
    discountedPrice: '4,500',
    frillDetails: [
      'Maximum of two (2) hours per visit',
      'Price is applicable to Metro Manila areas only *',
    ],
    frillImage: frillsOcularVisit,
    hasPriceStartLabel: true,
    isMirrored: true,
    title: 'Ocular Visit',
    price: '7,000',
  },
  {
    description:
      'Need a little helping hand? Have our interior designers visit you and work alongside them as they bring your dream space to life!',
    discountedPrice: '14,999',
    frillDetails: [
      'Must be purchased with at least one (1) Gussied Up Premium package and applicable for intended area only',
      'Maximum of eight (8) hours for the actual styling, decorating, and accessorizing',
      'Access to a dedicated project manager and in-house interior designer',
      'A curated shopping list',
      'One (1) revision to the shopping list provided by our interior designer',
      'Price is applicable to Metro Manila areas only *',
    ],
    frillImage: frillsOnsiteStyling,
    hasPriceStartLabel: true,
    isMirrored: false,
    title: 'On-site Styling',
    price: '17,000',
  },
];
